import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { Page, PrintOnly } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import { setWidgetsLoaded } from 'js/reducers/report.js';
import { getDemographics1, getDemographics2, getYouthSysData } from './getStructuredData';
import demographicsQuery from './demographicsQuery.graphql';
import style from './style.css';
import AgeChart from './Charts/AgeChart';
import PieChart from './Charts/PieChart';
import AgeIcon from './images/AgeIcon.svg';
import GenderIcon from './images/GenderIcon.svg';
import LanguageIcon from './images/LanguageIcon.svg';
import MembersIcon from './images/MembersIcon.svg';
import YSIIconA from './images/YSIIconA.svg';
import YSIIconB from './images/YSIIconB.svg';
import format from 'date-fns/format';
import _unique from 'lodash/uniq';
import Tooltip from 'cccisd-tooltip';
import DemographicsTable from './Charts/DemographicsTable';

const Fortress = window.cccisd.fortress;
const userRole = Fortress.user.acting.data_type;
const isAdmin = userRole === 'uberadmin' || userRole === 'orgAdmin';

const Demographics = props => {
    const [data, setData] = useState(null);
    const { settings } = props;

    useEffect(() => {
        (async () => {
            await getDemographicsData(props.assignmentProgressIds);
        })();
    }, [props.assignmentProgressIds]);

    const getDemographicsData = async idList => {
        try {
            const response = await client.query({
                query: demographicsQuery,
                fetchPolicy: 'no-cors',
                variables: {
                    idList,
                },
            });

            if (response && response.data && response.data.flows.assignmentProgressList.length > 0) {
                props.setWidgetsLoaded('demographics');
                setData(response.data.flows.assignmentProgressList);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getText = type => {
        const text = {
            foster: {
                devTag: 'FosterCare',
                text: 'of respondents have spent time in foster care',
            },
            juvy: {
                devTag: 'JuvyCenter',
                text: 'have spent time in a juvenile detention center or an adult correctional facility',
            },
        };

        const textObj = text[type];

        const respondentCount = data.length;

        const typeCount = data.filter(item => +item.devTags[textObj.devTag]).length;

        const sectionPercentage = () => {
            if (respondentCount === 0) {
                return 0;
            }
            return (typeCount / respondentCount) * 100;
        };

        const rounded = Math.round(sectionPercentage() * 10) / 10;

        return `${rounded}% ${textObj.text}`;
    };

    const capFirst = string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const getSiteLabels = () => {
        const { siteList } = props;
        const classIds = props.treeSelection;
        const labels = [];

        siteList.forEach(site => {
            const children = site.childGroups.classList;
            const match = classIds.find(item => item === site.group.groupId);
            if (match) {
                const childMatches = [];
                children.forEach(child => {
                    const childMatch = classIds.find(item => item === child.group.groupId);
                    if (childMatch) {
                        childMatches.push(child);
                    }
                });

                labels.push({ site, childMatches });
            }
        });

        if (labels.length > 0) {
            return _unique(labels);
        }
        return [];
    };

    const tooltips = {
        age: (
            <div>
                {' '}
                <p>Reflects % of respondents reporting each age as shown in the chart.</p>
                <p>Variable AGE </p>
            </div>
        ),
        gender: (
            <div>
                <p>
                    Reflects % of respondents that identified the gender category that “best describes” them from the
                    available choices.
                </p>
                <p>Variable GENDER</p>
            </div>
        ),
        race: (
            <div>
                <p>
                    Reflects % of respondents that identified the race/ethnicity category that “best describes” them
                    from the available choices.
                </p>
                <p>Variable RACEBST</p>
            </div>
        ),
        livingSit: (
            <div>
                <p>
                    Reflects % of respondents identifying the living situation category as their current living
                    situation.
                </p>
                <p>Variable LIVSITU</p>
            </div>
        ),
        houseMembers: (
            <div>
                <p>
                    Reflects % of respondents identifying the household members with whom they currently live.
                    Respondents were asked to identify all household members with whom they are currently living,
                    therefore, this chart will show greater than 100% when adding across all types of living
                    arrangements.
                </p>
                <p>
                    Variables MOM_NOWLIVE, DAD_NOWLIVE, SMOM_NOWLIVE, SDAD_NOWLIVE, GMOM_NOWLIVE, GDAD_NOWLIVE,
                    SU_NOWLIVE, SIB_NOWLIVE, OTHER_NOWLIVE
                </p>
            </div>
        ),
        language: (
            <div>
                <p>
                    Reflects % of respondents identifying the language category as their primary language used in their
                    household.
                </p>
                <p>Variable HMLANG</p>
            </div>
        ),
        youthSys: (
            <div>
                <p>
                    Reflects % of respondents identifying the that they have had prior experience in either the foster
                    care or juvenile justice systems.
                </p>
                <p>Variables FOSTER and JUVY</p>
            </div>
        ),
    };

    const dqcKey = {
        0: 'Dishonest',
        1: 'Speeder',
        2: 'Skipper',
        3: 'Inconsistent Responses',
        4: 'Unlikely Responses',
        5: 'Endorsed Fake Drug Use',
    };

    const tagWidths = {
        gender: { width: '25%', paddingTop: '1em' },

        ethnicity: { width: '133.75px' },

        livingSituation: { width: '222.14px', marginRight: '1em' },

        householdMembers: { width: '155px' },
    };

    const ageData = data && getDemographics1(data, 'age');
    const genderData = data && getDemographics2(data, 'gender');
    const ethnicityData = data && getDemographics2(data, 'ethnicity');
    const livingSituationData = data && getDemographics2(data, 'livingSituation');
    const householdMembersData = data && getDemographics2(data, 'householdMembers');
    const languageData = data && getDemographics1(data, 'language');
    const youthSysData = data && getYouthSysData(data);

    const respondentCount = data && data.length;

    const tableData = data && {
        age: ageData,
        gender: genderData,
        ethnicity: ethnicityData,
        livingSituation: livingSituationData,
        householdMembers: householdMembersData,
        language: languageData,
        youthSys: youthSysData,
    };

    return (
        <>
            <div style={{ padding: '1em' }}>
                {!data ? (
                    <div style={{ display: 'block', width: '100%', textAlign: 'center' }}>
                        <Page>
                            <Skeleton height={50} style={{ marginBottom: '1em' }} />
                            <Skeleton height={275} width="93.5%" style={{ marginBottom: '2em' }} />
                            <Skeleton height={50} style={{ marginBottom: '1em' }} />
                            <div style={{ textAlign: 'center', marginBottom: '2em' }}>
                                <Skeleton height={500} width="45%" style={{ marginRight: '2em' }} />
                                <Skeleton height={500} width="45%" />
                            </div>
                        </Page>
                        <Page>
                            <Skeleton height={50} style={{ marginBottom: '1em' }} />
                            <div style={{ textAlign: 'center', marginBottom: '2em' }}>
                                <Skeleton height={500} width="45%" style={{ marginRight: '2em' }} />
                                <Skeleton height={500} width="45%" style={{ marginBottom: '1em' }} />
                                <Skeleton height={275} width="93.5%" style={{ marginBottom: '2em' }} />
                            </div>
                            <Skeleton height={50} style={{ marginBottom: '1em' }} />
                            <Skeleton height={275} width="93.5%" style={{ marginBottom: '2em' }} />
                        </Page>
                    </div>
                ) : (
                    <>
                        <Page>
                            <PrintOnly>
                                <h3 className={style.summaryTitle}>Youth Experience Survey (YES) Overview Report</h3>
                                <hr />
                                <div className={style.printheader}>
                                    <div className={style.leftSide}>
                                        <strong>Number of Respondents:</strong>{' '}
                                        <span>
                                            {isAdmin
                                                ? `${props.assignmentProgressIds.length} of ${props.assignmentCount}`
                                                : `${props.assignmentProgressIds.length}`}
                                        </span>
                                        {isAdmin && (
                                            <div className={style.leftItem}>
                                                <strong>Data Quality Checks:</strong>
                                                {props.filters.validation.length > 0 ? (
                                                    <ul>
                                                        {props.filters.validation.map((item, i) => (
                                                            <li key={i}>{dqcKey[item]}</li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    ' None'
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <div className={style.leftItem}>
                                            <strong>Created:</strong> {format(Date.now(), 'MM/DD/YYYY')}
                                        </div>
                                        <div className={style.leftItem}>
                                            <strong>School Level:</strong> {capFirst(props.filters.level)}
                                        </div>
                                        <div className={style.leftItem}>
                                            <strong>Date Range:</strong> {format(props.filters.startDate, 'MM/DD/YYYY')}{' '}
                                            - {format(props.filters.endDate, 'MM/DD/YYYY')}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <strong>Selected Sites:</strong>{' '}
                                    <div className={style.labelBox}>
                                        <ul>
                                            {getSiteLabels()?.map((label, i) => {
                                                return (
                                                    <li
                                                        key={i}
                                                        style={{
                                                            pageBreakInside: 'avoid',
                                                        }}
                                                    >
                                                        {label.site.group.label}{' '}
                                                        <ul>
                                                            {label.childMatches.map((child, id) => (
                                                                <li
                                                                    key={id}
                                                                    style={{
                                                                        pageBreakInside: 'avoid',
                                                                    }}
                                                                >
                                                                    {child.group.label}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </PrintOnly>
                        </Page>
                        <Page>
                            <div className={style.ageBanner}>{settings.ageBannerTitle}</div>
                            <div className={style.chartArea}>
                                <div className={`${style.whiteBox} ${style.ageBox}`}>
                                    <div className={style.flex}>
                                        <div className={style.legendRight}>% of Respondents that Answered</div>
                                        <div className={style.chartSide}>
                                            <Tooltip title={tooltips.age}>
                                                <div className={style.chartTitle}>
                                                    <img className={style.bannerIcon} src={AgeIcon} alt="AgeIcon" />
                                                    {settings.ageChartTitle}
                                                </div>
                                            </Tooltip>
                                            <AgeChart
                                                respondentCount={respondentCount}
                                                arr={ageData}
                                                legendText="Age of Respondents"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Page>
                        <Page>
                            <div className={style.ageBanner}>{settings.GEBannerTitle}</div>
                            <div className={style.doubleChartArea}>
                                <div className={`${style.whiteBox} ${style.genEthBox}`}>
                                    <Tooltip title={tooltips.gender}>
                                        <div className={style.chartTitle}>
                                            <img className={style.bannerIcon} src={GenderIcon} alt="GenderIcon" />
                                            {settings.genderChartTitle}
                                        </div>
                                    </Tooltip>
                                    <PieChart
                                        respondentCount={respondentCount}
                                        arr={genderData}
                                        tagWidth={tagWidths.gender}
                                    />
                                </div>
                                <div className={`${style.whiteBox} ${style.genEthBox}`}>
                                    <Tooltip title={tooltips.race}>
                                        <div className={style.chartTitle}>
                                            <img className={style.bannerIcon} src={GenderIcon} alt="GenderIcon" />
                                            {settings.ethnicityChartTitle}
                                        </div>
                                    </Tooltip>
                                    <PieChart
                                        respondentCount={respondentCount}
                                        arr={ethnicityData}
                                        tagWidth={tagWidths.ethnicity}
                                    />
                                </div>
                            </div>
                        </Page>
                        <Page>
                            <div className={style.ageBanner}>{settings.LSBannerTitle}</div>
                            <div className={style.doubleChartArea}>
                                <div className={`${style.whiteBox} ${style.genEthBox}`}>
                                    <Tooltip title={tooltips.livingSit}>
                                        <div className={style.chartTitle}>
                                            <img className={style.bannerIcon} src={MembersIcon} alt="MembersIcon" />
                                            {settings.situationChartTitle}
                                        </div>
                                    </Tooltip>
                                    <PieChart
                                        respondentCount={respondentCount}
                                        arr={livingSituationData}
                                        tagWidth={tagWidths.livingSituation}
                                    />
                                </div>
                                <div className={`${style.whiteBox} ${style.genEthBox}`}>
                                    <Tooltip title={tooltips.houseMembers}>
                                        <div className={style.chartTitle}>
                                            <img className={style.bannerIcon} src={MembersIcon} alt="MembersIcon" />
                                            {settings.membersChartTitle}
                                        </div>
                                    </Tooltip>
                                    <PieChart
                                        respondentCount={respondentCount}
                                        arr={householdMembersData}
                                        tagWidth={tagWidths.householdMembers}
                                    />
                                </div>
                            </div>
                        </Page>

                        <Page>
                            <div className={style.chartArea}>
                                <div className={`${style.whiteBox} ${style.ageBox}`}>
                                    <div className={style.flex}>
                                        <div className={style.legendRight}>% of Answering Respondents</div>
                                        <div className={style.chartSide}>
                                            <Tooltip title={tooltips.language}>
                                                <div className={style.chartTitle}>
                                                    <img
                                                        className={style.bannerIcon}
                                                        src={LanguageIcon}
                                                        alt="LanguageIcon"
                                                    />
                                                    {settings.languageChartTitle}
                                                </div>
                                            </Tooltip>
                                            <AgeChart
                                                respondentCount={respondentCount}
                                                arr={languageData}
                                                legendText="Language Spoken"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style.ageBanner}>{settings.involvementBannerTitle}</div>
                            <div className={style.chartArea}>
                                <div className={style.whiteBox}>
                                    <Tooltip title={tooltips.youthSys}>
                                        <div className={style.bottomStats}>
                                            <div className={style.stat}>
                                                <img className={style.bannerIcon} src={YSIIconA} alt="YSIIconA" />
                                                <div className={style.chartTitle}>{getText('foster')}</div>
                                            </div>

                                            <div className={style.stat}>
                                                <img className={style.bannerIcon} src={YSIIconB} alt="YSIIconB" />
                                                <div className={style.chartTitle}>{getText('juvy')}</div>
                                            </div>
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        </Page>
                        <PrintOnly>
                            <Page>
                                <DemographicsTable tableData={tableData} respondentCount={respondentCount} />
                            </Page>
                        </PrintOnly>
                    </>
                )}
            </div>
        </>
    );
};

Demographics.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    // redux
    siteList: PropTypes.array,
    setWidgetsLoaded: PropTypes.func,
    assignmentProgressIds: PropTypes.array,
    treeSelection: PropTypes.array,
    assignmentCount: PropTypes.number,
};

const mapStateToProps = state => ({
    assignmentProgressIds: state.app.report.assignmentProgressIds,
    siteList: state.app.report.siteList,
    treeSelection: state.app.report.treeSelection,
    assignmentCount: state.app.report.assignmentCount,
});

export default connect(mapStateToProps, { setWidgetsLoaded })(Demographics);
